import React from "react";
import { isAndroid, isIOS } from "react-device-detect";

const DeviceStoreButtons = () => {
    if (isAndroid) {
        return (
            <a href="https://play.google.com/store/apps/details?id=es.treenovum.swissicehockey.scbern" target="_blank">
                <img
                    alt="Get it on Google Play"
                    src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                    width="180"
                />
            </a>
        );
    }
    else if (isIOS) {
        return (
            <a href="https://apps.apple.com/ch/app/sc-bern/id1021985290" target="_blank">
                <img
                    alt="Download on the App Store"
                    src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    width="160"
                />
            </a>
        );
    }
    // If the device is a desktop or an unrecognized device, display both buttons.
    else {
        return (
            <div className="store-buttons">
                <a href="https://play.google.com/store/apps/details?id=es.treenovum.swissicehockey.scbern" target="_blank">
                    <img
                        alt="Get it on Google Play"
                        src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                    />
                </a>

                <a href="https://apps.apple.com/ch/app/sc-bern/id1021985290" target="_blank">
                    <img
                        alt="Download on the App Store"
                        src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                    />
                </a>
            </div>
        );

    }
};

export default DeviceStoreButtons;