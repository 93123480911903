import API from '../libraries/API';

/**
 * User's data (loggedin user, my data)
 */
const data = () => API.get('user/me');

/**
 * Update user's data (loggedin user, my data)
 * @param {Object} params
 * @param {string} params.username - Username
 * @param {string} params.password - Password
 * @param {string} params.email - Email address
 * @param {string} params.first_name - First name
 * @param {string} params.last_name - Last name
 * @param {string} params.street - Street address
 * @param {string} params.zip - ZIP code
 * @param {string} params.city - City name
 * @param {string} params.country - Country name
 * @param {string} params.phone - Phone number
 * @param {string} params.birthday - Date of birth
 * @param {string} params.gender - Gender
 */
const update = params => API.patch('user/me', params);

/**
 * Verify user's email
 * @param {Object} params
 * @param {string} params.code - Code from email
 */
const verifyEmail = ({ code }) => API.get(`user/verify-email/${code}`);

/**
 * Resend email for verification
 * @param {Object} params
 */
const resendEmail = () => API.post('user/verify-email');

/**
 * Fetch passes for loggedin user
 */
const passes = () => API.get('user/me/passes');

export default {
  data,
  update,
  verifyEmail,
  resendEmail,
  passes,
}
